/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';
export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'es';
export const localeOptions = [
  { id: 'en', name: 'English - LTR', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
];

export const apiUrl = "https://aliseda.coctelcreativo.com/api/";
//export const apiUrl = "https://imem.coctelcreativo.com/api/";
export const uploads_url = "https://aliseda.coctelcreativo.com/api/uploads";
//export const apiUrl = "http://localhost/api/api/";
//export const uploads_url = "http://localhost/api/uploads/";

export const adminRoot = '/app';
export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = false;
export const defaultColor = 'light.purplemonster';
export const isDarkSwitchActive = false;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isDemo = false;